<script>
/******************************************************************************
 * AUTH PAGE
 *****************************************************************************/
import {APP_DESCRIPTION} from "@/config";
import Icon from "@/components/Icon/Icon";
import PageSection from "@/components/PageSection/PageSection";
import FormRow from "@/components/FormRow/FormRow";
import NavRow from "@/components/NavRow/NavRow";
import RequestProgressing from "@/components/RequestProgressing/RequestProgressing";
const EVENTS = ["online", "offline", "load"];
export default {
    data: function () {
        return {
            customerNo: this.$store.getters.customerNo,
            meterNo: this.$store.getters.meterNo,
            isOnline: window.navigator.onLine || true
        }
    },
    components: {
        Icon,
        PageSection,
        FormRow,
        NavRow,
        RequestProgressing
    },
    computed: {
        errorClass () { return this.authError ? "error" : ""; },
        requesting () { return this.$store.getters.requesting; },
        appDescription () { return APP_DESCRIPTION; },
        serverError () { return this.$store.getters.serverError; },
        authError () { return this.$store.getters.authError; },
        isSubmitDisabled () { // returns true if submit should be disabled
            // add online check
            return !this.customerNo || !this.meterNo || this.requesting || !this.isOnline;
        }
    },
    methods: {
        doAuth () {
            return this.$store.dispatch(
                "DO_AUTH", {customerNo: this.customerNo, meterNo: this.meterNo}
            );
        },
        onChangeCustomerNo () {
            this.$store.dispatch("SET_CUSTOMERNO", this.customerNo);
        },
        onChangeMeterNo () {
            this.$store.dispatch("SET_METERNO", this.meterNo);
        },
        onEnterPressed () {
            // only "submit" when conditions are met
            if (this.customerNo && this.meterNo && !this.requesting) {
                this.doAuth();
            }
        },
        updateOnlineStatus: function() {
            if (typeof window.navigator.onLine === "undefined") {
                this.isOnline = true;
            } else {
                this.isOnline = window.navigator.onLine;
            }
        }
    },
    mounted () {
        EVENTS.forEach(event => window.addEventListener(event, this.updateOnlineStatus));
        const _body = document.querySelector("body");
        if (_body.getAttribute("data-proceed") === "once") {
            _body.removeAttribute("data-proceed");
            this.doAuth();
        }
    },
    beforeDestroy() {
        EVENTS.forEach(event => window.removeEventListener(event, this.updateOnlineStatus));
    }
}
</script>

<template>
    <main>
        <page-section :hdl="appDescription">
            <p>
                Bitte melden Sie sich mit Ihrer Kunden- und einer Zählernummer an.
                Sie finden diese Daten unter anderem auf Ihrer Rechnung oder der Ablesekarte.
            </p>
            <p>
                Wenn Sie mehrere Zählernummern haben, wählen Sie eine beliebige.
            </p>
        </page-section>
        <page-section hdl="Anmeldung">
            <form>

                <form-row
                    ref-id="customerno"
                    label="Kundennummer ohne Punkt"
                    :class="errorClass">
                    <template slot="input">
                        <input
                            type="text"
                            id="customerno"
                            name="customerno"
                            placeholder="Kundennummer"
                            v-model="customerNo"
                            maxlength="20"
                            @keyup.enter="onEnterPressed()"
                            @keyup="onChangeCustomerNo()"
                            required>
                    </template>
                </form-row>

                <form-row
                    ref-id="meterno"
                    label="Zählernummer"
                    :class="errorClass">
                    <template slot="input">
                        <input
                            type="text"
                            id="meterno"
                            name="meterno"
                            placeholder="Zählernummer"
                            v-model="meterNo"
                            maxlength="20"
                            @keyup.enter="onEnterPressed()"
                            @keyup="onChangeMeterNo()"
                            required>
                    </template>
                    <template slot="error"><span v-html="authError" /></template>

                </form-row>

                <div
                    v-if="serverError"
                    class="server-error">
                    <span v-html="serverError" />
                </div>

                <nav-row>
                    <template slot="right">
                        <button
                            type="button"
                            :disabled="isSubmitDisabled"
                            :aria-disabled="isSubmitDisabled"
                            @click="doAuth">
                            <request-progressing v-if="requesting" />
                            Anmelden
                            <icon
                                class="icon-next"
                                name="next" />
                        </button>
                    </template>
                </nav-row>

            </form>
        </page-section>
    </main>
</template>

<style lang="scss" scoped>
    .claim {
        margin: 0 0 1em 0;

        font-size: #{map-get($sizes, "base") * 1.4};
        font-weight: 400;
    }

    .auth-error,
    .server-error {
        margin: 0 0 map-get($sizes, "base");

        color: palette("state", "error");

        &::first-letter { font-size: 1.6em; }

        @include respond-to("medium") { text-align: center; }
    }

    .auth-error {
        clear: both;
    }

    .icon-next {
        float: right;

        margin: 7px 0 0 5px;
    }
</style>
